/* Data --------------------------------------------------------------- */

import { ICookiesPopupProps } from '../components/common/CookiesPopUp';
import { IFooterProps } from '../components/common/layout/Footer';
import { INavBarProps } from '../components/common/layout/NavBar';

export interface IStrapiCollectionResult<T> {
  data: IStrapiData<T>[];
  meta?: IStrapiMeta;
}

export interface IStrapiData<T> {
  id?: number;
  attributes: T;
}

export interface IStrapiMeta {
  pagination?: IPagination;
}

export interface IPagination {
  page?: number;
  pageSize?: number;
  pageCount?: number;
  total?: number;
}

export interface IBlogPostsResult {
  results: IBlogPost[];
  pagination: IPagination;
}

export interface IBlogPost {
  id?: number;
  title: string;
  body: string;
  author?: string;
  metaDescription?: string;
  metaKeywords?: string;
  slug?: string;
  publicationDate?: Date;
  createdAt?: Date;
  updatedAt?: Date;
  publishedAt?: Date;
  locale?: string;
  image?: IMedia;
  createdBy?: IUser;
  updatedBy?: IUser;
  localizations?: ILocalization[];
  isFeatured?: boolean;
}

export interface IUser {
  id?: number;
  username?: null;
  email?: string;
  password?: string;
  resetPasswordToken?: null;
  registrationToken?: null;
  isActive?: boolean;
  blocked?: boolean;
  preferedLanguage?: null;
  createdAt?: Date;
  updatedAt?: Date;
  user_info?: IUserInfo;
}

export interface IMe {
  id?: number;
  email: string;
  user_info: IUserInfo;
  requestIp: string;
  countryCode: string; // ISO3
}

export interface IHeaders {
  accept: string;
  authorization: string;
  'user-agent': string;
  'cache-control': string;
  'postman-token': string;
  host: string;
  'accept-encoding': string;
  connection: string;
}

export interface IUserInfo {
  id?: number;
  name: string;
  newsletter: boolean;
  phone: string;
  createdAt: Date;
  updatedAt: Date;
  currency: ICurrency;
  assignedConsultant: IUser;
  language: ILanguage;
}

export interface IMediaFormats {
  thumbnail?: IMediaFormat;
  small?: IMediaFormat;
  large?: IMediaFormat;
  medium?: IMediaFormat;
}

export interface IMediaFormat {
  name?: string;
  hash?: string;
  ext?: string;
  mime?: string;
  path?: null;
  width?: number;
  height?: number;
  size?: number;
  url?: string;
}

export interface ILocalization {
  id?: number;
  title?: string;
  body?: string;
  author?: string;
  metaDescription?: string;
  slug?: string;
  publicationDate?: Date;
  createdAt?: Date;
  updatedAt?: Date;
  publishedAt?: Date;
  locale?: string;
}

export interface ILanguage {
  id?: number;
  twoLetterIso?: string;
  threeLetterIso?: string;
  nameTranslations?: ISimpleTranslation[];
}

export interface ICountry {
  id?: number;
  iso3: string;
  iso: string;
  internalName: string;
  numericIso: number;
  createdAt: Date;
  updatedAt: Date;
  dividesByClass: boolean;
  dateFromWhichValidityIsCalculated: string;
  estimatedProcessingMonths: number;
  yearsOfValidity: number;
  currency: ICurrency;
  nameTranslations: ISimpleTranslation[];
  searchClassPrices: IApplicationClassPrice[];
  applicationClassPrices: IApplicationClassPrice[];
  finalFees: IApplicationClassPrice[];
}

export interface ICountryRegion {
  nameTranslations: ISimpleTranslation[];
  countries: ICountry[];
  featuredCountries?: ICountry[];
  copyrightCountries?: ICountry[];
  trademarkRenewalCountries?: ICountry[];
  industrialDesignCountries?: ICountry[];
  order: number;
}

export interface ICurrency {
  id?: number;
  isoCode?: string;
  numericIso?: number;
  numberOfDecimals?: number;
  internalName?: string;
  symbol?: string;
  conversionRate?: number;
  nameTranslations?: ISimpleTranslation[];
}

export interface ISubClass {
  subClassId: number;
  descriptionTranslations: ISimpleTranslation[];
}

export interface IClass {
  id?: number;
  code: number;
  descriptionTranslations: ISimpleTranslation[];
  subClasses?: ISubClass[];
}

export interface IApplicationClassPrice {
  id?: number;
  value: number;
  valueName?: string;
}

export interface ISimpleTranslation {
  id?: number;
  language?: string;
  text?: string;
}

export interface IMedia {
  id?: number;
  name?: string;
  alternativeText?: null;
  caption?: null;
  width?: number;
  height?: number;
  formats?: IMediaFormats;
  hash?: string;
  ext?: string;
  mime?: string;
  size?: number;
  url?: string;
  previewUrl?: null;
  provider?: string;
  provider_metadata?: IMediaProviderMetadata;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface IMediaProviderMetadata {
  public_id?: string;
  resource_type?: string;
}

export interface ILocale {
  id?: number;
  name?: string;
  code?: string;
  createdAt?: Date;
  updatedAt?: Date;
  isDefault?: boolean;
}

export interface IContactMethod {
  id?: number;
  labelTranslations: ISimpleTranslation[];
  valueTranslations?: ISimpleTranslation[];
  type: 'phone' | 'email'
}

export interface ICustomerReview {
  id?: number;
  content: string;
  customerName: string;
  customerLocation: string;
  score: number;
}

export enum OrderStatus {
  COMPLETED = 'completed',
  CANCELED = 'canceled',
  DRAFT = 'draft',
  PENDING = 'pending',
  PAID_AND_PENDING_TRACKER_CREATION = 'paidAndPendingTrackersCreation',
  PAID_BUTTON_ERROR_CREATING_TRACKERS = 'paidButErrorCreatingTrackers'
}

export interface IOrder {
  id?: number;
  user?: number | IUser;
  billingAddress?: string;
  billingCity?: string;
  billingName?: string;
  billingPostcode?: string;
  billingTitle?: string;
  billingTaxId?: string;
  billingCountry?: number | ICountry;
  currency?: number | ICurrency;
  totalPrice?: number;
  status: OrderStatus;
  payment?: number | IPayment;
  searchTrackers?: ISearchTracker[];
  trademarkTrackers?: ITrademarkTracker[];
  orderItems?: IOrderItem[];
  createdAt?: Date | string;
  updatedAt?: Date | string;
}

export interface IPayment {
  orders?: number[] | IOrder[];
  type?: 'card' | 'bankwire' | 'other' | 'attorney_services';
  status: 'pending' | 'completed' | 'refunded';
  currency?: number | ICurrency;
  paid: number;
  paymentInternalId: string;
  createdByAdmin?: boolean;
  lastInvoice?: IMedia;
  previousInvoices?: IMedia[];
  paymentConfirmationEmailSent?: boolean;
  used?: number;
  balance?: number;
  user?: IUser | number;
}

export enum TrackerStatus {
  IN_PROCESS = 'in_process',
  REGISTERED = 'registered',
  FILED = 'filed',
  COMPLETED = 'completed',
  INACTIVE = 'inactive',
  ABANDONED = 'abandoned',
  REFUSED = 'refused',
  WAITIN_FOR_POA = 'waiting_for_poa'
}

export interface ITrackerFile {
  file: IMedia;
  description?: string;
}

export interface ITrademarkTracker {
  id?: number;
  trackerTitle: string;
  trademarkText: string;
  user?: number | IUser;
  country?: number | ICountry;
  logo?: number | IMedia;
  classes?: { class: IClass; description?: string }[];
  ownerAddress?: string;
  ownerCity?: string;
  ownerCountry?: number | ICountry;
  ownerPostCode?: string;
  ownerName?: string;
  ownerAddressAlias?: string;
  attorney?: number | IUser;
  status: TrackerStatus;
  filingNumber?: string;
  filingDate: string | Date;
  registrationNumber?: string;
  registrationDate?: string | Date;
  renewalDate?: string | Date;
  deadlineDate?: string | Date;
  order?: number | IOrder;
  files?: ITrackerFile[];
}

export interface ISearchTracker {
  id?: number;
  trackerTitle: string;
  trademarkText: string;
  user?: number | IUser;
  country?: number | ICountry;
  logo?: number | IMedia;
  classes?: { class: IClass; description?: string }[];
  ownerAddress?: string;
  ownerCity?: string;
  ownerCountry?: number | ICountry;
  ownerPostCode?: string;
  ownerName?: string;
  ownerAddressAlias?: string;
  attorney?: number | IUser;
  status: TrackerStatus;
  uploadDate?: string | Date;
  results?: number;
  order?: number | IOrder;
  files?: ITrackerFile[];
  report?: IMedia;
}

export enum ServiceType {
  SEARCH = 'search',
  APPLICATION = 'application',
  ADDTIONAL_SERVICE = 'additional_service',
  INDUSTRIAL_DESIGN = 'industrial_design',
  COPYRIGHT = 'copyright'
}

export interface ICountryPrice {
  price?: number;
  country?: ICountry | number;
}

export interface IOrderItem {
  id?: number;
  order?: number | IOrder;
  serviceType?: ServiceType;
  trademarkText?: string;
  logo?: IMedia | number;
  ownerAddress?: string;
  ownerCity?: string;
  ownerCountry?: number | ICountry;
  ownerPostCode?: string;
  ownerName?: string;
  ownerAddressAlias?: string;
  countries?: ICountry[] | number[];
  classes?: IOrderItemClass[] | number[] | any[];
  prices?: ICountryPrice[];
}

export interface IOrderItemClass {
  id?: number;
  class: IClass | number | any;
  description?: string;
}

export interface IAddress {
  id?: number;
  user?: number | IUser;
  alias?: string;
  name?: string;
  address?: string;
  postCode?: string;
  city?: string;
  country?: number | ICountry;
  taxId?: string;
}

export interface ICulture {
  countryCode?: string;
  currencyCode?: string;
}

export interface ILog404 {
  url: string;
  timestamp: Date;
  referrerUrl?: string;
  user?: IUser | number;
  userAgent?: string;
}

/* Data --------------------------------------------------------------- */

/* Pages  ------------------------------------------------------------- */
export interface IPage {
  meta: IMetaComponent[];
}

export interface IHomePage extends IPage {
  hero: IHeroComponent[];
  featuredServices: IHomeFeaturedServicesComponent[];
  quickAccessTitle: ISimpleTranslation[];
  quickAccessSubTitle: ISimpleTranslation[];
  quickAccessShowAllButton: ISimpleTranslation[];
  trustedBy: ITrustedByComponent[];
  customerReviews: ICustomerReviewsComponent[];
  contactUsForm: IContactUsForm[];
  contactUsFormSuccessMessage: ISimpleTranslation[];
  contactUsFormErrorMessage: ISimpleTranslation[];
}

export interface IBlogPage extends IPage {
  sortOrder: 'asc' | 'desc';
  sortByPropertyName: string;
  contactUsCallToAction: IContactUsCallToActionComponent[];
  contactUsForm: IContactUsForm[];
  contactUsFormSuccessMessage: ISimpleTranslation[];
  contactUsFormErrorMessage: ISimpleTranslation[];
}

export interface IAboutPage extends IPage {
  contactUsForm: IContactUsForm[];
  contactUsFormSuccessMessage: ISimpleTranslation[];
  contactUsFormErrorMessage: ISimpleTranslation[];
  hero: IHeroComponent[];
  topHighlightedTitle: IHighlightedTitleComponent[];
  howItWorksOne: ISimpleTranslation[];
  howItWorksTwo: ISimpleTranslation[];
  howItWorksThree: ISimpleTranslation[];
  howItWorksTitle: ISimpleTranslation[];
  valueAddedHighlightedTitle: IHighlightedTitleComponent[];
  valueAddedFixedPricesTitle: ISimpleTranslation[];
  valueAddedFixedPricesDescription: ISimpleTranslation[];
  valueAddedOnlineMonitoringTitle: ISimpleTranslation[];
  valueAddedOnlineMonitoringDescription: ISimpleTranslation[];
  valueAddedTrustworthinessTitle: ISimpleTranslation[];
  valueAddedTrustworthinessDescription: ISimpleTranslation[];
  valueAddedSingleChannelTitle: ISimpleTranslation[];
  valueAddedSingleChannelDescription: ISimpleTranslation[];
  valueAddedServiceTailoredTitle: ISimpleTranslation[];
  valueAddedServiceTailoredDescription: ISimpleTranslation[];
  ourServicesTitle: ISimpleTranslation[];
  ourServicesTrademarkList: IServiceListComponent[];
  ourServicesIndustrialDesignList: IServiceListComponent[];
  ourServicesDomainNameList: IServiceListComponent[];
  ourServicesCopyrightList: IServiceListComponent[];
}

export interface ICookiesPolicyPage extends IPage {
  content: ISimpleTranslation[];
  anchorHtmlTagName?: string;
}

export interface IPrivacyPolicyPage extends IPage {
  content: ISimpleTranslation[];
  anchorHtmlTagName?: string;
}

export interface ITermsPage extends IPage {
  content: ISimpleTranslation[];
  anchorHtmlTagName?: string;
}

export interface IContactUsPage extends IPage {
  title: ISimpleTranslation[];
  subTitle: ISimpleTranslation[];
  formSuccessMessage: ISimpleTranslation[];
  formErrorMessage: ISimpleTranslation[];
  contactUsForm: IContactUsForm[];
}

export interface ICustomErrorPage404 extends IPage {
  heading: ISimpleTranslation[];
  formSuccessMessage: ISimpleTranslation[];
  formErrorMessage: ISimpleTranslation[];
  contactUsForm: IContactUsForm[];
}

export interface IPricingPage extends IPage {
  title: ISimpleTranslation[];
  subTitle: ISimpleTranslation[];
  countries: ICountry[];
  countryNotListedTitle: ISimpleTranslation[];
  countryNotListedSubTitle: ISimpleTranslation[];
  countryNotListedButtonLabel: ISimpleTranslation[];
  businessKeyValues: IBusinessKeyValuesComponent[];
  trademarkSearchLabel: ISimpleTranslation[];
  trademarkRegistrationLabel: ISimpleTranslation[];
  contactUsForm: IContactUsForm[];
  contactUsFormSuccessMessage: ISimpleTranslation[];
  contactUsFormErrorMessage: ISimpleTranslation[];
}

export interface ICountryListPage extends IPage {
  title: ISimpleTranslation[];
  subTitle: ISimpleTranslation[];
  contactUsForm: IContactUsForm[];
  contactUsFormSuccessMessage: ISimpleTranslation[];
  contactUsFormErrorMessage: ISimpleTranslation[];
  trademarkRegistrationServiceName: ISimpleTranslation[];
  trademarkSearchServiceName: ISimpleTranslation[];
}

export interface ICountryPage extends IPage {
  heroTipsTitle: ISimpleTranslation[];
  heroTipOne: ISimpleTranslation[];
  heroTipTwo: ISimpleTranslation[];
  heroTipThree: ISimpleTranslation[];
  businessKeyValues: IBusinessKeyValuesComponent[];
  trustedBy: ITrustedByComponent[];
  customerReviews: ICustomerReviewsComponent[];
  fromLabel: ISimpleTranslation[];
  orderNowLabel: ISimpleTranslation[];
  formSuccessMessage: ISimpleTranslation[];
  formErrorMessage: ISimpleTranslation[];
  contactUsForm: IContactUsForm[];
  relatedServicesTitle: ISimpleTranslation[];
}

export interface ITrademarkLanding {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  publishedAt?: Date;
  country: ICountry;
  metas: IMetaComponent[];
  keywords: ISimpleTranslation[];
  trademarkHeaders: ISimpleTranslation[];
  landingPageSubTitles: ISimpleTranslation[];
  trademarkSearchTitles: ISimpleTranslation[];
  trademarkSearchTexts: ISimpleTranslation[];
  trademarkApplicationTitles: ISimpleTranslation[];
  trademarkApplicationTexts: ISimpleTranslation[];
  bodies: ISimpleTranslation[];
}

export interface IGenericServiceLanding {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  publishedAt?: Date;
  country: ICountry;
  metas: IMetaComponent[];
  heroHeaders: ISimpleTranslation[];
  heroSubTitles: ISimpleTranslation[];
  serviceTitles: ISimpleTranslation[];
  serviceTexts: ISimpleTranslation[];
  bodies: ISimpleTranslation[];
  fromPrice: number;
}

export interface IBankWireInformation {
  title: ISimpleTranslation[];
  currencyCode: string;
  bankLabel: ISimpleTranslation[];
  bankCodeLabel: ISimpleTranslation[];
  accountLabel?: ISimpleTranslation[];
  routingLabel?: ISimpleTranslation[];
  ibanLabel?: ISimpleTranslation[];
  ukSortCodeLabel?: ISimpleTranslation[];
  bankContent: string;
  bankCodeContent: string;
  accountContent?: string;
  routingContent?: string;
  ibanContent?: string;
  ukSortCodeContent?: string;
}

export interface ICheckoutPage extends IPage {
  stepOneTitle: ISimpleTranslation[];
  stepTwoTitle: ISimpleTranslation[];
  stepThreeTitle: ISimpleTranslation[];
  countrySelectionTitle: ISimpleTranslation[];
  countrySelectionPlaceholder: ISimpleTranslation[];
  countrySelectionAddButton: ISimpleTranslation[];
  trademarkInformationTitle: ISimpleTranslation[];
  trademarkInformationPlaceholder: ISimpleTranslation[];
  uploadLogoTip: ISimpleTranslation[];
  goodsAndServicesTitle: ISimpleTranslation[];
  goodsAndServicesDescription: ISimpleTranslation[];
  goodsAndServicesNotice: ISimpleTranslation[];
  goodsAndServicesButtonLabel: ISimpleTranslation[];
  goodsAndServicesButtonTip: ISimpleTranslation[];
  goodsAndServicesSearchPlaceholder: ISimpleTranslation[];
  ownerTitle: ISimpleTranslation[];
  ownerDescription: ISimpleTranslation[];
  addressesEmptyPlaceholder: ISimpleTranslation[];
  ownerCompanyLabel: ISimpleTranslation[];
  ownerCompanyPlaceholder: ISimpleTranslation[];
  ownerTaxIdLabel: ISimpleTranslation[];
  ownerTaxIdPlaceholder: ISimpleTranslation[];
  ownerAddressesTitle: ISimpleTranslation[];
  ownerAddAddressButton: ISimpleTranslation[];
  orderSummaryTitle: ISimpleTranslation[];
  billingTitle: ISimpleTranslation[];
  billingCreditCardTabName: ISimpleTranslation[];
  billingBankWireTabName: ISimpleTranslation[];
  billingBankWireNotice: ISimpleTranslation[];
  billingBankWireNoticeLink: ISimpleTranslation[];
  billingBankWireNoticeLinkLabel: ISimpleTranslation[];
  billingBankWireBeneficiaryLabel: ISimpleTranslation[];
  billingBankWireBeneficiary: ISimpleTranslation[];
  billingBankWireAddressLabel: ISimpleTranslation[];
  billingBankWireAddress: ISimpleTranslation[];
  billingBankWireInformation: IBankWireInformation[];
  totalLabel: ISimpleTranslation[];
  backButton: ISimpleTranslation[];
  nextButton: ISimpleTranslation[];
  payButton: ISimpleTranslation[];
  goodsAndServicesClassLabel: ISimpleTranslation[];
  addressSaveSuccessMessage: ISimpleTranslation[];
  addressSaveErrorMessage: ISimpleTranslation[];
  addressDeletePromptMessage: ISimpleTranslation[];
  addressDeleteSuccessMessage: ISimpleTranslation[];
  addressDeleteErrorMessage: ISimpleTranslation[];
  addressEditButton: ISimpleTranslation[];
  addressDeleteButton: ISimpleTranslation[];
  countryRemoveButton: ISimpleTranslation[];
  uploadLogoTitle: ISimpleTranslation[];
  uploadLogoRemoveButton: ISimpleTranslation[];
  goodsAndServicesSelectedTitle: ISimpleTranslation[];
  goodsAndServicesRemoveButton: ISimpleTranslation[];
  goodsAndServicesModalTitle: ISimpleTranslation[];
  goodsAndServicesModalDescription: ISimpleTranslation[];
  goodsAndServicesModalCancelButton: ISimpleTranslation[];
  goodsAndServicesModalDoneButton: ISimpleTranslation[];
  goodsAndServicesClassDescripionPlaceholder: ISimpleTranslation[];
  billingBankWireClipboardTip: ISimpleTranslation[];
  termsCheckboxLabel: ISimpleTranslation[];
  billingPaymentMethodsTitle: ISimpleTranslation[];
  addressBookCreateOrModify: IAddressBookCreateOrModify[];
  noServiceAvailableMessage?: ISimpleTranslation[];
}

export interface ICheckoutBankwireResultPage extends IPage {
  title: ISimpleTranslation[];
  message: ISimpleTranslation[];
  button: ISimpleTranslation[];
}

export interface ICheckoutCreditCardResultPage extends IPage {
  indeterminateTitle: ISimpleTranslation[];
  indeterminateMessage: ISimpleTranslation[];
  successTitle: ISimpleTranslation[];
  successMessage: ISimpleTranslation[];
  successButton: ISimpleTranslation[];
  errorTitle: ISimpleTranslation[];
  errorMessage: ISimpleTranslation[];
  errorButton: ISimpleTranslation[];
}

export interface IOAuthConnectResultPage extends IPage {
  successTitle: ISimpleTranslation[];
  successMessage: ISimpleTranslation[];
  errorTitle: ISimpleTranslation[];
  errorMessage: ISimpleTranslation[];
  errorButton: ISimpleTranslation[];
}

export interface ILoginPage extends IPage {
  emailLabel: ISimpleTranslation[];
  passwordLabel: ISimpleTranslation[];
  forgotPasswordButton: ISimpleTranslation[];
  logInButton: ISimpleTranslation[];
  registerTip: ISimpleTranslation[];
  registerButton: ISimpleTranslation[];
  formSubmitErrorMessage: ISimpleTranslation[];
  emailNotValidMessage: ISimpleTranslation[];
  emailRequiredMessage: ISimpleTranslation[];
  passwordRequiredMessage: ISimpleTranslation[];
  alternativeLoginMethodsTitle: ISimpleTranslation[];
}

export interface IForgotPasswordPage extends IPage {
  description: ISimpleTranslation[];
  emailLabel: ISimpleTranslation[];
  submitButton: ISimpleTranslation[];
  emailRequiredMessage: ISimpleTranslation[];
  emailNotValidMessage: ISimpleTranslation[];
  formSubmitSuccessMessage: ISimpleTranslation[];
  formSubmitErrorMessage: ISimpleTranslation[];
}

export interface IRegisterPage extends IPage {
  nameLabel: ISimpleTranslation[];
  emailLabel: ISimpleTranslation[];
  passwordLabel: ISimpleTranslation[];
  confirmPasswordLabel: ISimpleTranslation[];
  newsletterCheckboxLabel: ISimpleTranslation[];
  termsCheckboxLabel: ISimpleTranslation[];
  submitButton: ISimpleTranslation[];
  logInTip: ISimpleTranslation[];
  logInButton: ISimpleTranslation[];
  nameRequiredMessage: ISimpleTranslation[];
  emailNotValidMessage: ISimpleTranslation[];
  emailRequiredMessage: ISimpleTranslation[];
  passwordRequiredMessage: ISimpleTranslation[];
  passwordConfirmationRequiredMessage: ISimpleTranslation[];
  passwordNotMatchMessage: ISimpleTranslation[];
  termsRequiredMessage: ISimpleTranslation[];
  formSubmitSuccessMessage: ISimpleTranslation[];
  formSubmitErrorMessage: ISimpleTranslation[];
}

export interface IResetPasswordPage extends IPage {
  codeLabel: ISimpleTranslation[];
  passwordLabel: ISimpleTranslation[];
  confirmPasswordLabel: ISimpleTranslation[];
  submitButtonLabel: ISimpleTranslation[];
  formSubmitSuccessMessage: ISimpleTranslation[];
  formSubmitErrorMessage: ISimpleTranslation[];
  loginButtonLabel: ISimpleTranslation[];
}

export interface IAccountAddressBookPage extends IPage {
  title: ISimpleTranslation[];
  createNewAddressButton: ISimpleTranslation[];
  emptyTitle: ISimpleTranslation[];
  emptyMessage: ISimpleTranslation[];
  emptyButton: ISimpleTranslation[];
  formSubmitSuccessMessage: ISimpleTranslation[];
  formSubmitErrorMessage: ISimpleTranslation[];
  addressBookCreateOrModify: IAddressBookCreateOrModify[];
}

export interface IAccountOrdersPage extends IPage {
  pendingServicesTitle: ISimpleTranslation[];
  pendingServicesColumnService: ISimpleTranslation[];
  pendingServicesColumnClasses: ISimpleTranslation[];
  pendingServicesColumnCountry: ISimpleTranslation[];
  pendingServicesColumnPrice: ISimpleTranslation[];
  pendingServicesColumnName: ISimpleTranslation[];
  pendingServicesRemoveButton: ISimpleTranslation[];
  pendingServicesEditButton: ISimpleTranslation[];
  pendingServicesBatchPayButton: ISimpleTranslation[];
  paidOrdersTitle: ISimpleTranslation[];
  paidOrdersColumnOrderNumber: ISimpleTranslation[];
  paidOrdersColumnDate: ISimpleTranslation[];
  paidOrdersColumnState: ISimpleTranslation[];
  paidOrdersColumnTotal: ISimpleTranslation[];
  paidOrdersInvoiceButton: ISimpleTranslation[];
  emptyTitle: ISimpleTranslation[];
  emptyMessage: ISimpleTranslation[];
  emptyButton: ISimpleTranslation[];
  serviceNameTrademarkApplication: ISimpleTranslation[];
  serviceNameTrademarkSearch: ISimpleTranslation[];
  serviceNameAdditional: ISimpleTranslation[];
}

export interface IAccountPortfolioPage extends IPage {
  title: ISimpleTranslation[];
  trademarksLabel: ISimpleTranslation[];
  trademarksSearchPlaceholder: ISimpleTranslation[];
  trademarksColumnName: ISimpleTranslation[];
  trademarksColumnLogo: ISimpleTranslation[];
  trademarksColumnCountry: ISimpleTranslation[];
  trademarksColumnStatus: ISimpleTranslation[];
  trademarksColumnRenewalDate: ISimpleTranslation[];
  trademarksOpenButton: ISimpleTranslation[];
  trademarksEmtpyTitle: ISimpleTranslation[];
  trademarksEmtpyMessage: ISimpleTranslation[];
  trademarksEmtpyButton: ISimpleTranslation[];
  searchesLabel: ISimpleTranslation[];
  searchesSearchPlaceholder: ISimpleTranslation[];
  searchesColumnName: ISimpleTranslation[];
  searchesColumnLogo: ISimpleTranslation[];
  searchesColumnCountry: ISimpleTranslation[];
  searchesColumnStatus: ISimpleTranslation[];
  searchesReportButton: ISimpleTranslation[];
  searchesEmptyTitle: ISimpleTranslation[];
  searchesEmptyMessage: ISimpleTranslation[];
  searchesEmptyButton: ISimpleTranslation[];
  editorTitle: ISimpleTranslation[];
  editorDetailsTitle: ISimpleTranslation[];
  editorDetailsTrademarkLabel: ISimpleTranslation[];
  editorDetailsLogoLabel: ISimpleTranslation[];
  editorDetailsClassesLabel: ISimpleTranslation[];
  editorDetailsOwnerNameLabel: ISimpleTranslation[];
  editorDetailsOwnerAddressLabel: ISimpleTranslation[];
  editorDetailsStatusLabel: ISimpleTranslation[];
  editorDetailsFillingDateLabel: ISimpleTranslation[];
  editorDetailsFillingNumberLabel: ISimpleTranslation[];
  editorDetailsRegistrationNumberLabel: ISimpleTranslation[];
  editorDetailsRenewalDateLabel: ISimpleTranslation[];
  editorFilesTitle: ISimpleTranslation[];
  editorFilesDownloadButton: ISimpleTranslation[];
}

export interface IAccountProfilePage extends IPage {
  myAccountTitle: ISimpleTranslation[];
  myAccountNameLabel: ISimpleTranslation[];
  myAccountEmailLabel: ISimpleTranslation[];
  myAccountPreferredLanguageLabel: ISimpleTranslation[];
  myAccountPreferredCurrencyLabel: ISimpleTranslation[];
  myAccountConsultantLabel: ISimpleTranslation[];
  myAccountConsultantNameLabel: ISimpleTranslation[];
  myAccountConsultantEmailLabel: ISimpleTranslation[];
  myAccountPhoneNumberLabel: ISimpleTranslation[];
  myAccountNewsletterLabel: ISimpleTranslation[];
  myAccountSubmitButton: ISimpleTranslation[];
  changePasswordTitle: ISimpleTranslation[];
  changePasswordOldPasswordLabel: ISimpleTranslation[];
  changePasswordNewPasswordLabel: ISimpleTranslation[];
  changePasswordNewPasswordConfirmLabel: ISimpleTranslation[];
  changePasswordSubmitButton: ISimpleTranslation[];
  myAccountFormSubmitSuccessMessage: ISimpleTranslation[];
  myAccountFormErrorSuccessMessage: ISimpleTranslation[];
  changePasswordFormSubmitSuccessMessage: ISimpleTranslation[];
  changePasswordErrorSuccessMessage: ISimpleTranslation[];
  validationNameIsRequired: ISimpleTranslation[];
  validationEmailIsRequired: ISimpleTranslation[];
  validationEmailIsNotValid: ISimpleTranslation[];
  validationPhoneIsNotValid: ISimpleTranslation[];
  validationOldPasswordIsRequired: ISimpleTranslation[];
  validationNewPasswordIsRequired: ISimpleTranslation[];
  validationPasswordMinLength: ISimpleTranslation[];
  validationConfirmPasswordIsRequired: ISimpleTranslation[];
  validationPasswordNotMatch: ISimpleTranslation[];
}

export interface IServiceWithTopicsPage extends IPage {
  heroTipsTitle: ISimpleTranslation[];
  heroTipsSubTitle: ISimpleTranslation[];
  heroTipsDescription: ISimpleTranslation[];
  heroTipOne: ISimpleTranslation[];
  heroTipTwo: ISimpleTranslation[];
  heroTipThree: ISimpleTranslation[];
  heroTipFour: ISimpleTranslation[];
  heroImage: IMedia;
  introductionTitle: ISimpleTranslation[];
  introductionDescription: ISimpleTranslation[];
  introductionImage: IMedia;
  topics: IPageContentBlockWithTitleComponent[];
  countryListCallToAction: IHeroComponent[];
  businessKeyValues: IBusinessKeyValuesComponent[];
  contactUsForm: IContactUsForm[];
  contactUsFormSuccessMessage: ISimpleTranslation[];
  contactUsFormErrorMessage: ISimpleTranslation[];
}

export interface IMadridSystemPage extends IServiceWithTopicsPage { }

export interface IInternationalTrademarkRegistrationPage extends IServiceWithTopicsPage { }

/* Pages  ------------------------------------------------------------- */

/* SEO Components  ---------------------------------------------------- */
export interface IMetaComponent {
  title: string;
  description: string;
  slug: string;
  language: string;
  keywords?: string;
}

/* SEO Components  ---------------------------------------------------- */

/* Common  ------------------------------------------------------------ */

export interface ICommonTranslations {
  serviceTypeSearch: ISimpleTranslation[];
  serviceTypeApplication: ISimpleTranslation[];
  serviceTypeAdditionalService: ISimpleTranslation[];
  serviceTypeIndustrialDesign: ISimpleTranslation[];
  serviceTypeCopyright: ISimpleTranslation[];
  requiredField: ISimpleTranslation[];
  passwordMinimumLength: ISimpleTranslation[];
  passwordsMustMatch: ISimpleTranslation[];
  emailFormatNotValid: ISimpleTranslation[];
  termsAndConditionsMustBeAccepted: ISimpleTranslation[];
  showMore: ISimpleTranslation[];
  showLess: ISimpleTranslation[];
  feesIncluded: ISimpleTranslation[];
}

export interface IEntitiesStatus {
  trackerInProcess: ISimpleTranslation[];
  trackerRegistered: ISimpleTranslation[];
  trackerFiled: ISimpleTranslation[];
  trackerCompleted: ISimpleTranslation[];
  trackerInactive: ISimpleTranslation[];
  trackerAbandoned: ISimpleTranslation[];
  trackerRefused: ISimpleTranslation[];
  trackerWaitingForPoa: ISimpleTranslation[];
  trackerUK00918095923: ISimpleTranslation[];
  paymentPending: ISimpleTranslation[];
  paymentDone: ISimpleTranslation;
  orderCompleted: ISimpleTranslation[];
  orderCanceled: ISimpleTranslation[];
  orderDraft: ISimpleTranslation[];
  orderPending: ISimpleTranslation[];
  orderPaidAndPendingTrackersCreation: ISimpleTranslation[];
}

export interface IAddressBookCreateOrModify {
  language: string;
  aliasLabel?: string;
  aliasPlaceholder: string;
  nameLabel?: string;
  namePlaceholder: string;
  addressLabel?: string;
  addressPlaceholder: string;
  postCodeLabel?: string;
  postCodePlaceholder: string;
  cityLabel?: string;
  cityPlaceholder: string;
  countryLabel?: string;
  countryPlaceholder: string;
  referenceSectionTitle: string;
  nameSectionTitle: string;
  addressSectionTitle: string;
  taxIdLabel?: string;
  taxIdPlaceholder?: string;
  title: string;
  saveButton: string;
  deleteButton: string;
}

/* Common  ------------------------------------------------------------ */

/* Layout and UI ------------------------------------------------------ */

export interface ILayoutPageProps {
  locale: string;
  meta: IMetaComponent;
  navBarProps: INavBarProps;
  cookiesPopUpProps: ICookiesPopupProps;
  footerProps: IFooterProps;
}

export interface INavBarItem {
  label: string;
  path: string;
}

export interface IMainNavBar {
  servicesLabel: string;
  servicesItems: INavBarItem[];
  pricingItem: INavBarItem;
  blogItem: INavBarItem;
  aboutUsItem: INavBarItem;
  loginLabel: string;
  logoutLabel: string;
  accountProfileLabel: string;
  contactUsLabel: string;
  ordersLabel: string;
  portfolioLabel: string;
  addressBookLabel: string;
}

export interface IMainFooter {
  servicesLabel: string;
  servicesItems: INavBarItem[];
  contactLabel: string;
  paymentLabel: string;
  copyright: string;
  disclaimer: string;
  cookiesPolicyItem: INavBarItem;
  privacyItem: INavBarItem;
  termsItem: INavBarItem;
}

export interface ICookiesPopup {
  message: string;
  secondaryButtonLabel: string;
  primaryButtonLabel: string;
}

export interface IHeroComponent {
  title: string;
  description: string;
  buttonLabel: string;
  background?: IMedia | null | undefined;
  language: string;
  highlightedTitleText?: string | undefined;
}

export interface IHomeFeaturedServicesComponent {
  language: string;
  title: string;
  subTitle: string;
  description: string;
  serviceOneTitle: string;
  serviceOneDescription: string;
  serviceTwoTitle: string;
  serviceTwoDescription: string;
}

export interface ITrustedByComponent {
  language: string;
  title: string;
  logos: IMedia[];
}

export interface IContactUsCallToActionComponent {
  language: string;
  title: string;
  description: string;
  buttonLabel: string;
  image: IMedia;
}

export interface IBusinessKeyValuesComponent {
  language: string;
  title: string;
  description: string;
  keyValueOneTitle: string;
  keyValueOneDescription: string;
  keyValueTwoTitle: string;
  keyValueTwoDescription: string;
  keyValueThreeTitle: string;
  keyValueThreeDescription: string;
  keyValueFourTitle: string;
  keyValueFourDescription: string;
}

export interface ICustomerReviewsComponent {
  language: string;
  title: string;
  subTitle: string;
  description: string;
  logo?: IMedia;
  logoLink?: string;
}

export interface IAccountLeftNavBar {
  profileLabel: ISimpleTranslation[];
  ordersLabel: ISimpleTranslation[];
  portfolioLabel: ISimpleTranslation[];
  trademarksLabel: ISimpleTranslation[];
  searchesLabel: ISimpleTranslation[];
  addressBookLabel: ISimpleTranslation[];
}

export interface IContactUsForm {
  language: string;
  title: string;
  subTitle: string;
  emailLabel: string;
  nameLabel: string;
  messageLabel: string;
  submitLabel: string;
}

export interface IHighlightedTitleComponent {
  language: string;
  title: string;
  subTitle: string;
  highlightedText?: string;
}

export interface IServiceListComponent {
  language: string;
  title: string;
  items: string;
}

export interface IPageContentBlockWithTitleComponent {
  language: string;
  title: string;
  text: string;
}

export interface ICountryRelatedService {
  title: string;
  description: string;
  slug: string;
}

/* Layout and UI ------------------------------------------------------ */